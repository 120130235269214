iframe#arethusa {
  width: 100%;
  height: 700px;
  border: none;
}

#arethusa.full {
  height: 100%;
  position: fixed;
  top: 30px;
  left: 0;
}

.line-margin {
  width: 60px;
  color: #777;
  background: #fff;
  margin-left: -60px;
  display: inline-block;
}

.token {
  padding: 2px 4px;
  display: inline-block;
  position: relative;
}

.token span {
  float: none;
  clear: both;
  display: block;
}

.sentence:nth-child(2n):hover .token, .sentence:nth-child(2n+1):hover .token {
  background: linen;
}

.token {
  position: relative;
}

.token:hover .token-reg {
  display: inline;
}

.token:hover .token-orig {
  display: none;
}

.token .token-reg {
  color: green;
  display: none;
}

.token .token-orig {
  color: red;
}

.token:hover {
  text-decoration: underline;
}

.tooltip table {
  border-collapse: collapse;
}

.tooltip {
  visibility: hidden;
  z-index: 1;
  background-color: #fff;
  border-radius: 6px;
  padding: 10px;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 75%;
  position: absolute;
  bottom: 135%;
  left: 65%;
  box-shadow: 0 5px 10px #0000004d;
}

.tooltip td, .tooltip th {
  min-width: 75px;
  line-height: 1.3em;
}

.tooltip th {
  padding-bottom: 3px;
}

.tooltip td {
  padding-right: 6px !important;
}

.token:hover .tooltip {
  visibility: visible;
}

textarea.xml {
  width: 100%;
  border: 0;
}

.hljs {
  color: #000;
  font-size: 15px;
}

.hljs > * {
  opacity: .7;
  font-size: 12px;
}

.card-content.diff {
  font-size: 0;
}

.card-content.diff * {
  font-size: 11px;
}

.diff .removed {
  color: #fff;
  background: red;
}

.diff .removed.spacer {
  color: #fff;
  min-width: 8px;
  min-height: 1.15em;
  background: red;
  display: inline-block;
  position: relative;
  top: 3px;
}

.diff .added {
  color: #fff;
  background: green;
}

.diff .unchanged {
  color: #00f;
}

xmp {
  white-space: pre-wrap;
  display: inline;
}

table#editor, table.metadata {
  table-layout: fixed;
  width: 100%;
  font-size: 11px !important;
}

table#editor th:nth-child(3), table#editor th:nth-child(8) {
  width: 7% !important;
}

table#editor th:nth-child(4), table#editor th:nth-child(9) {
  width: 6% !important;
}

table#editor th:nth-child(5), table#editor th:nth-child(10) {
  width: 5% !important;
}

table#editor th:nth-child(11) {
  width: 2% !important;
}

table#editor th:nth-child(12) {
  width: 8% !important;
}

table#editor th:nth-child(13) {
  width: 7% !important;
}

table#editor th:nth-child(14) {
  width: 1% !important;
}

table#editor .layer-row th {
  color: #2f4f4f;
  font-size: 13px;
  line-height: 34px;
}

table#editor .delete td {
  color: red;
  text-decoration: line-through;
}

table#editor td:nth-child(2), table#editor td:nth-child(3), table#editor td:nth-child(4), table#editor td:nth-child(5) {
  background: #f0f8ff;
}

table#editor td:nth-child(7), table#editor td:nth-child(8), table#editor td:nth-child(9), table#editor td:nth-child(10) {
  background: #fff0f5;
}

table#editor td:nth-child(1), table#editor td:nth-child(6), table#editor td:nth-child(11), table#editor td:nth-child(12), table#editor td:nth-child(13) {
  background: snow;
}

table#editor td.different {
  box-shadow: inset 0 0 30px #0003;
}

.delete-artificial {
  cursor: pointer;
}

.new-artificial {
  background: #90ee90;
}

.token-editable.modified, .token-editable.modified:empty {
  color: #8b008b;
  border: 1px solid #000;
}

.import-xml:empty:not(:focus):before {
  content: attr(data-placeholder);
  text-align: center;
  color: gray;
  width: 100%;
  font-size: 12px;
  display: inline-block;
}

table.metadata th, table.metadata td {
  text-align: center;
  vertical-align: top;
}

.metadata-card {
  border: 1px solid silver;
  border-radius: 4px;
}

.metadata-card .card-title {
  width: 15%;
  border-bottom: 2px solid gray;
  margin: 0 auto;
  padding-bottom: 4px;
}

.text-type-items {
  margin: 10px 0;
}

/*# sourceMappingURL=index.38e99a6e.css.map */
